@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Lato', sans-serif;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-logo {
  margin-bottom: 150px;
}

.notification-icon {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  right: -8px;
  top: -17px;
  font-size: 0.75rem;
  font-weight: 500;
}

.notifications-icon svg {
  display: block;
  margin: auto;
}


/* background-color: rgb(237 82 105 / 0.1); */


/* .card-background  {
  position: relative;
  display: block;
}

  .card-background :after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgb(0,0,0) 50%);
      top: 0;
      left: 0;
  } */

/* .masonry-card img{
    filter: brightness(65%);
  } */

@media only screen and (max-width: 767px) {
  .profile-sidebar,
    .mainPageButtons {
      display: none !important;
    }
  
    .alignLike {
      padding: 12px;
    }
  
    #lblFileInputUser {
      padding: 38px !important
    }
  
    .register-form {
      width: 75%;
    }
}

.align-username-add {
  display: flex;
  justify-content: center;
}

.align-username-add svg {
  margin: 15px;
  border: 1px solid #eeeeee;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
  margin-bottom: 5px;
}

#fullname,
#email,
#password,
#confirm_pwd,
#username {
  border: 2px solid #eeeeee;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 12px;
  padding-left: 2.5rem;
}

/* .masonry-card {
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
  padding: 6px
} */

/* .masonry-card > div > img {
  box-shadow: inset 0 13px 37px 55px rgb(0, 0, 0, 0.5) !important;
} */

#lblFileInput {
  outline: 2px dashed #E30613;
  outline-offset: -10px;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 48px;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  display: block;
}

#lblFileInputUser {
  outline: 2px dashed #E30613;
  outline-offset: -10px;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 60px;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  display: block;
}

.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  margin-bottom: 20px;
}

.profile-image {
  width: 120px;
  height: 120px;
  /* max-width: 100%; */
  object-fit: cover;
  border-radius: 50%;
}

.mainPageButtons,
.mainPageButtons-main {
  display: grid;
}

.mainPageButtons button,
.mainPageButtons-main button {
  margin: 10px;
  background-color: #191919;
  color: #E30613
}

.likeCount {
  margin-top: 20px;
  margin-left: 10px;
}

.alignLikeCount {
  display: flex;
}

.alignLike {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-vinylimg {
  filter: sepia(0%) saturate(129%) brightness(66%) hue-rotate(330deg);
}

/* register form css */

/* #fullname,
#email,
#password,
#confirm_pwd,
#username{
  /* border: none; 
} */
.input-register svg{
  position: absolute;
    left: 10px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    top: 6px;
  }

  .input-register p{
    margin-bottom: 10px;
  }
.input-register-icons {
  display: flex;
  position: absolute;
  right: 9px;
  top: 1px;
}

.btnAuth {
  background-color: #E30613;
}

.registerError {
  text-align: center;
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.line a {
  color: #E30613;
  font-weight: bold;
  margin-left: 5px;
}

.instructions {
  font-size: 0.6rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -4px;
}

.instructions>svg {
  margin-right: 0.25rem;
}

[type=text]:focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=url]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  border: 1px solid black;
}

#email-login:focus , #password-login:focus{
  box-shadow: none;
    border: none;
}

.h-screen-sidebar {
  height: 200vh;
}

.site-description {
  color: #E30613;
  text-align: center;
}

/* end register form css */

#paging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px; 
}

/* // Dropzone css */

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px #E30613 dashed;
  width: 100%;
  margin: auto;
}

.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 45px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
  padding: 12px;
  margin-top: 20px;
}

.btn:hover {
  background-color: #E30613;
  color: aliceblue;
}

.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}

.file-list img {
  height: 300px;
  width: 300px;
  padding-right: 10px;
  object-fit: cover;
}

/* end dropzone css */